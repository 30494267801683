<script>
    import {timetable} from "./timetable.json";
</script>

<table>
    <tr>
        <th></th>
        <th>Subject Title</th>
        <th>Date</th>
        <th>Start</th>
        <th>End</th>
        <th>Room</th>
    </tr>
    {#each timetable as subject}
        <tr>
            <td>
                <h2>{subject.courseId}</h2>
                {#each subject.assessments as assessment}
                    <p>{assessment.level}</p>
                {/each}
            </td>
            <td>
                <h2>{subject.name}</h2>
                {#each subject.assessments as assessment}
                    <p>{assessment.name}</p>
                {/each}
            </td>
            <td>
                <h2>⠀</h2>
                {#each subject.assessments as assessment}
                    <p>{assessment.date}</p>
                {/each} 
            </td>
            <td>
                <h2>⠀</h2>
                {#each subject.assessments as assessment}
                    <p>{assessment.startTime}</p>
                {/each} 
            </td>
            <td>
                <h2>⠀</h2>
                {#each subject.assessments as assessment}
                    <p>{assessment.endTime}</p>
                {/each} 
            </td>
            <td>
                <h2>⠀</h2>
                {#each subject.assessments as assessment}
                    <p>{assessment.room}</p>
                {/each} 
            </td>
        </tr>
    {/each}
    
</table>

<style>
    table {
        border-collapse: collapse;
        width: calc(100vw - 2rem);
        margin: 1rem;
    }

    th,
    td {
        text-align: left;
        padding: 8px;
    }

    tr {
        background-color: lightgray
    }
    tr:nth-child(even) {
        background-color: #f2f2f2;
    }
    
    th {
        background-color: purple;
        color: white;
    }
</style>