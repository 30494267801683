<script>
	import Timetable from "./Timetable.svelte";
</script>

<main>
	<h1>Exam Timetable</h1>
	<Timetable></Timetable>
</main>

<style>
	main {
		text-align: center;
		background: deeppink;
		height: 100vh;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	h1 {
		color: white;
		text-transform: uppercase;
		font-size: 4em;
		font-weight: 100;
		padding: 2rem;
	}
</style>